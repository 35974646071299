/**
 * @function elementReady
 * @description Returns a Promise that resolves when the given element is loaded
 * @param {string} selector The element selector
 * @returns {Promise} Promise that resolves when the given element is loaded
 */
const MAX_TIMEOUT = 10000

export function elementReady(selector: string): Promise<Element> {
  return new Promise((resolve, reject) => {
    const el = document.querySelector(selector)
    if (el) {
      return resolve(el)
    }

    const observer = new MutationObserver((_, observer) => {
      Array.from(document.querySelectorAll(selector)).forEach((element) => {
        resolve(element)
        observer.disconnect()
      })
    })

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true
    })

    setTimeout(() => {
      reject(`Element ${selector} not found`)
    }, MAX_TIMEOUT)
  })
}
