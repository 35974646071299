import type { BanquetCustomProps } from '@toasttab/banquet-types'
import { useBanquetPropsBase } from '../useBanquetPropsBase'

/**
 * The props given to your SPA via Banquet and Single SPA.
 */
export const useBanquetProps: typeof useBanquetPropsBase<
  BanquetCustomProps
> = () => useBanquetPropsBase<BanquetCustomProps>()

export interface BanquetProps extends ReturnType<typeof useBanquetProps> {}
