import { BanquetCustomProps } from '@toasttab/banquet-types'
import { AppProps } from 'single-spa'
import { banquetSingleSpaReactOpts } from '../banquetSingleSpaReact'

/**
 * Defines the lifecycle functions for managing the CSS scope of a child Single-SPA React application.
 * @param options - The options for configuring the CSS scope.
 * @returns An object containing the lifecycle functions.
 */
export function childSpaCssScopeLifeCycle(options: banquetSingleSpaReactOpts): {
  bootstrap: (
    props: BanquetCustomProps & AppProps
  ) => Promise<BanquetCustomProps & AppProps>
} {
  return {
    /**
     * The bootstrap function that sets the CSS scope attribute on the provided DOM element.
     * @param props - The custom props and app props passed to the child application.
     * @returns A promise that resolves to the modified props.
     */
    bootstrap(props: BanquetCustomProps & AppProps) {
      if (props?.domElement) {
        props.domElement.setAttribute(options.cssScope, 'true')
      }
      return Promise.resolve(props)
    }
  }
}
