/**
 * Mounts an element with a CSS scope.
 * @param options - The options for mounting the element.
 * @returns A function that mounts the element with the given options.
 */
import { chooseDomElementGetter } from 'dom-element-getter-helpers'
import { banquetSingleSpaReactOpts } from '../banquetSingleSpaReact/banquetSingleSpaReact'

export function mountElementWithScope(options: banquetSingleSpaReactOpts) {
  return function (props: any) {
    // @ts-ignore The `domElementGetter` definition is wrong, but will be fixed soon
    // @see https://github.com/single-spa/dom-element-getter-helpers/pull/4
    const element = chooseDomElementGetter(options, props)()
    if (options.cssScope) {
      // Should we remove it on unmount, or let `single-spa` do its things?
      element.setAttribute(options.cssScope, 'true')
    }
    return element
  }
}
