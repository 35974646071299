import { useContext } from 'react'
import { SingleSpaContext } from 'single-spa-react'
import type { AppProps } from 'single-spa'
import { MissingContextError } from '../errors/MissingSingleSpaContextError'

/**
 * The props given to your SPA via Banquet and Single SPA.
 */
export const useBanquetPropsBase = <TCustomProps>(): TCustomProps &
  AppProps => {
  const result = useContext(SingleSpaContext) as TCustomProps & AppProps

  if (!result) {
    throw new MissingContextError('useBanquetPropsBase')
  }

  return result
}
