import * as React from 'react'
import { banquetSingleSpaReactOpts } from '../banquetSingleSpaReact'
import { initSentry as initSentryDefault } from './initSentry'
import { BanquetProps } from '../useBanquetProps'
import type {
  Breadcrumb,
  BreadcrumbHint,
  EventHint,
  Hub,
  SeverityLevel
} from '@sentry/browser'
import ErrorStackParser from 'error-stack-parser'
import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'
import {
  Permissions403DesktopIllustration,
  Permissions403MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import { getExternalDependencyVersions } from '@toasttab/report-external-dependencies'
import { sanitizeTagKeys } from './sanitizeTagKeys'
import { AppProps } from 'single-spa'

function createSentryHandler(name: string, hub: Hub) {
  return (e: ErrorEvent) => {
    const stack = e.error && ErrorStackParser.parse(e.error)
    const fileName = stack?.[0]?.fileName || e?.filename
    if (fileName && System.resolve(name) === fileName) {
      hub.run((currentHub) => {
        currentHub.addBreadcrumb({
          message: 'Error on spa ' + name,
          level: 'error',
          type: 'error'
        })
        currentHub.captureException(e.error || e.message)
      })
    }
  }
}

export type SentryContextValue = {
  hub: Hub
  captureException: (exception: Error, hint?: EventHint) => string
  addBreadcrumb: (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => void
  captureMessage: (
    message: string,
    level?: SeverityLevel,
    hint?: EventHint
  ) => string
}

const SentryContext = React.createContext<SentryContextValue>(
  {} as SentryContextValue
)
// THIS NEEDS ERROR HANDLING _ WHAT HAPPENS WHEN SENTRY FAILS
// IT SHOULD NOT KILL THE APP
export function banquetSentry({
  sentry,
  initSentry = initSentryDefault
}: banquetSingleSpaReactOpts) {
  if (!sentry) {
    return {
      lifecycles: {
        bootstrap: (props: AppProps) => Promise.resolve(props),
        unmount: (props: AppProps) => Promise.resolve(props),
        mount: (props: AppProps) => Promise.resolve(props)
      }
    }
  }

  if (!sentry.Sentry) {
    throw new Error(
      'Sentry is Missing: Sentry should be passed down via the banquetSingleSpaReact options since version 0.5'
    )
  }

  const { hub } = initSentry(sentry)
  const captureException: SentryContextValue['captureException'] = (...all) => {
    let eventId = ''
    hub.run((currentHub) => {
      eventId = currentHub.captureException(...all)
    })
    return eventId
  }
  const captureMessage: SentryContextValue['captureMessage'] = (...all) => {
    let eventId = ''
    hub.run((currentHub) => {
      eventId = currentHub.captureMessage(...all)
    })
    return eventId
  }
  const SentryErrorBoundary = (
    error: Error,
    info: React.ErrorInfo,
    props: BanquetProps
  ) => {
    const eventId = captureException(error)
    return (
      <ErrorContainer>
        <div>
          <div
            className='visible hidden mx-auto md:block'
            style={{ maxWidth: 600 }}
          >
            <Permissions403DesktopIllustration />
          </div>
          <div
            className='visible block mx-auto md:hidden'
            style={{ maxWidth: 250 }}
          >
            <Permissions403MobileIllustration />
          </div>
        </div>
        <div>
          <ErrorHeading>Oops! We have some cleaning up to do.</ErrorHeading>

          {isToastUserRole(props.auth?.userInfo.roles ?? []) ? (
            <ErrorSubheading>
              We've captured an error in {props.name}{' '}
              <a
                href={`https://sentry.io/organizations/toast/issues/?query=${eventId}`}
              >
                {eventId}
              </a>
            </ErrorSubheading>
          ) : (
            <ErrorSubheading>
              Not to worry. Our team is aware of the mess and they're busy
              scrubbing pots and pans.
            </ErrorSubheading>
          )}
        </div>
      </ErrorContainer>
    )
  }
  let handleErrorWithSentry: (e: ErrorEvent) => void = () => {}

  const bootstrap = (props: BanquetProps) => {
    const bundleName = props.name.endsWith(`-${props.mode}`)
      ? props.name.replace(`-${props.mode}`, '')
      : props.name
    handleErrorWithSentry = createSentryHandler(bundleName, hub)
    return new Promise((resolve) => {
      if (props?.auth?.userInfo?.guid || props?.auth?.userInfo?.email) {
        hub.setUser({
          id: props?.auth?.userInfo?.guid
        })
      }
      hub.setContext('Custom props', {
        name: props.name,
        bundleName,
        mode: props.mode,
        package_name: props.name,
        featureFlags: props.featureFlags,
        restaurantInfo: props.restaurantInfo
      })
      hub.addBreadcrumb({
        message: `${bundleName}${
          props.mode ? ' in mode ' + props.mode : ''
        } initialized`
      })
      hub.setTags(sanitizeTagKeys(getExternalDependencyVersions()))

      return resolve(props)
    })
  }

  const unmount = (props: AppProps) => {
    return new Promise((resolve) => {
      window.removeEventListener('error', handleErrorWithSentry)
      return resolve(props)
    })
  }
  const mount = (props: AppProps) => {
    return new Promise((resolve) => {
      window.addEventListener('error', handleErrorWithSentry)
      return resolve(props)
    })
  }
  const withSentry = (WrappedComponent: React.ComponentType<BanquetProps>) => {
    const ComponentWithSentry = (props: BanquetProps) => (
      <SentryContext.Provider
        value={{
          hub,
          captureException: captureException,
          captureMessage: captureMessage,
          addBreadcrumb: hub.addBreadcrumb.bind(hub)
        }}
      >
        <WrappedComponent {...props} />
      </SentryContext.Provider>
    )
    ComponentWithSentry.displayName = `withSentry(${
      WrappedComponent.displayName || 'Component'
    })`
    return ComponentWithSentry
  }

  return {
    sentryErrorBoundary: SentryErrorBoundary,
    withSentry,
    lifecycles: {
      bootstrap,
      unmount,
      mount
    }
  }
}

export const useSentry = () => {
  const context = React.useContext(SentryContext)
  if (!Object.keys(context).length) {
    throw new Error(
      'Sentry usage in banquetSingleSpaReact must be configured correctly.'
    )
  }
  return context
}

function isToastUserRole(roles: string[]): boolean {
  return roles.some((item: string) => item.startsWith('TOAST_'))
}
