import { banquetSingleSpaReactOpts } from '../banquetSingleSpaReact'
import { AppProps } from 'single-spa'
export function portalCssScope({
  portalContainers,
  cssScope
}: banquetSingleSpaReactOpts) {
  if (!portalContainers) {
    return {
      mount: (props: AppProps) => Promise.resolve(props),
      unmount: (props: AppProps) => Promise.resolve(props)
    }
  }

  function mount(props: AppProps) {
    portalContainers?.forEach((key: string) => {
      const element = document.getElementById(key)
      element?.setAttribute(cssScope, 'true')
    })
    return Promise.resolve(props)
  }

  function unmount(props: AppProps) {
    portalContainers?.forEach((key: string) => {
      const element = document.getElementById(key)
      element?.removeAttribute(cssScope)
    })
    return Promise.resolve(props)
  }

  return {
    mount,
    unmount
  }
}
