import * as React from 'react'
import { ParcelConfigObject } from 'single-spa'
import Parcel from 'single-spa-react/parcel'
import { BanquetCustomProps } from '@toasttab/banquet-types'
import { useJsLoader } from '../useJsLoader'
import { useBanquetProps } from '../useBanquetProps'

export const BanquetLoader = React.memo(
  <TCustomProps extends Record<string, any> = {}>({
    name,
    className = '',
    children,
    LoadingComponent = () => null,
    ...props
  }: BanquetCustomProps<TCustomProps>) => {
    const customProps = useBanquetProps()
    const {
      loading: jsLoading,
      bundle,
      error: jsError
    } = useJsLoader(name, props.mode)

    if (jsError) {
      console.error(jsError)
    }

    if (jsLoading) {
      return <LoadingComponent />
    }

    return (
      <Parcel
        {...props}
        {...customProps}
        // we need to set a key because Parcel doesn't rerender when the config changes
        key={bundle?.name}
        isBanquet
        appName={bundle?.name}
        wrapClassName={`single-spa-application:${bundle?.name} ${className}`}
        config={bundle as ParcelConfigObject}
      >
        {children}
      </Parcel>
    )
  }
)
