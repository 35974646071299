import { MissingContextError } from '../errors/MissingSingleSpaContextError'

import { useBanquetProps } from '../useBanquetProps'

/**
 * useIntlProps for use in l10n of dates, numbers and strings:
 * language: string representing locale,
 * t: Translate function
 */
export const useIntlProps = () => {
  const result = useBanquetProps()

  if (!result.i18n) {
    throw new MissingContextError('useIntlProps')
  }

  return result.i18n
}
