import { BanquetCustomProps } from '@toasttab/banquet-types'
import { chooseDomElementGetter } from 'dom-element-getter-helpers'
import { banquetSingleSpaReactOpts } from '../banquetSingleSpaReact'
import { resolveCSS, doesLinkExist, createLinkInHead, getName } from './utils'
import { AppProps } from 'single-spa'

export function banquetSingleSpaCss(options: banquetSingleSpaReactOpts): {
  domElementGetter: DomElementGetter
  lifecycles: {
    bootstrap: (
      props: BanquetCustomProps & AppProps
    ) => Promise<BanquetCustomProps & AppProps>
    mount: (
      props: BanquetCustomProps & AppProps
    ) => Promise<BanquetCustomProps & AppProps>
    unmount: (
      props: BanquetCustomProps & AppProps
    ) => Promise<BanquetCustomProps & AppProps>
  }
} {
  const mount = (props: BanquetCustomProps & AppProps) => {
    // You might not want the parcel to manage its own CSS
    if (props?.disableCssHandling) {
      return Promise.resolve(props)
    }
    if (options.singleSpaCssLifecycles) {
      return options.singleSpaCssLifecycles.mount(props)
    }

    return new Promise((resolve) => {
      try {
        const url = resolveCSS(getName(props))

        // If the url is not in the importmap OR the link already exists - return and do nothing
        if (!url || doesLinkExist(url)) {
          return resolve(props)
        }
        // Inject the link
        const link = createLinkInHead(getName(props), url)

        // listen for errors
        link.onerror = (e) => {
          // if the href exists delete it
          const href = doesLinkExist(url)
          if (href?.parentElement) {
            href.parentElement.removeChild(href)
          }
          // log an error to sentry
          console.error(e)
          // resolve the promise, we don't want a  filed css load to break
          resolve(props)
        }
        // Once loaded resolve the promise
        link.onload = () => {
          resolve(props)
        }
      } catch (e) {
        //  If anything throws an error resolved and continue
        return resolve(props)
      }
    })
  }

  const unmount = (props: BanquetCustomProps & AppProps) => {
    // You might not want the parcel to manage its own CSS
    if (props?.disableCssHandling) {
      return Promise.resolve(props)
    }
    if (options.singleSpaCssLifecycles) {
      return options.singleSpaCssLifecycles.unmount(props)
    }
    return new Promise((resolve) => {
      try {
        const url = resolveCSS(getName(props))
        if (url) {
          const href = doesLinkExist(url)
          if (href?.parentElement) {
            href.parentElement.removeChild(href)
          }
        }
        resolve(props)
      } catch {
        return resolve(props)
      }
    })
  }

  function bootstrap(props: BanquetCustomProps & AppProps) {
    if (props?.domElement) {
      props.domElement.setAttribute(options.cssScope, 'true')
    }
    if (options.singleSpaCssLifecycles) {
      return options.singleSpaCssLifecycles.bootstrap(props)
    }
    return Promise.resolve(props)
  }

  return {
    domElementGetter: mountElementWithScope(options),
    lifecycles: {
      bootstrap,
      unmount,
      mount
    }
  }
}

// Makes the `domElementGetter` non optional for the return type
type DomElementGetter = Required<banquetSingleSpaReactOpts>['domElementGetter']

function mountElementWithScope(
  options: banquetSingleSpaReactOpts
): DomElementGetter {
  return function (props: any) {
    // @ts-ignore The `domElementGetter` definition is wrong, but will be fixed soon
    // @see https://github.com/single-spa/dom-element-getter-helpers/pull/4
    const element = chooseDomElementGetter(options, props)()
    if (options.cssScope) {
      // Should we remove it on unmount, or let `single-spa` do its things?
      element.setAttribute(options.cssScope, 'true')
    }
    return element
  }
}
