// Based on rules defined here: https://docs.sentry.io/platforms/javascript/enriching-events/tags/
const INVALID_CHARACTERS = /([^a-z0-9.:_-])/gi

function replaceInvalidCharacters(key: string) {
  // Replacing with a space when the key starts with an invalid character, so
  // that it looks nicer (e.g. `@apollo/client` => `apollo_client`)
  return key.replace(INVALID_CHARACTERS, (_, __, offset) =>
    offset === 0 ? '' : '_'
  )
}

export function sanitizeTagKeys(tags: { [k: string]: string }): {
  [k: string]: string
} {
  const newTags: {
    [k: string]: string
  } = {}
  for (const key in tags) {
    newTags[replaceInvalidCharacters(key)] = tags[key]
  }
  return newTags
}
