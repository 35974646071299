import * as React from 'react'
import singleSpaReact, { SingleSpaReactOpts } from 'single-spa-react'
import { BanquetCustomProps } from '@toasttab/banquet-types'
import { banquetSingleSpaCss } from '../banquetSingleSpaCss'
import { banquetSentry, useSentry, initSentry } from '../banquetSentry'
import { waitForDomElement } from '../waitForDomElement'
import { useIntlProps } from '../useIntlProps'
import { portalCssScope } from '../portalCssScope'
import { AppProps, LifeCycleFn } from 'single-spa'
import { BanquetProps } from '../useBanquetProps'
import * as Sentry from '@sentry/browser'
import { childSpaCssScopeLifeCycle } from '../cssScope/childSpaCssScopeLifeCycle'
import { mountElementWithScope } from '../cssScope/mountElementWithScope'
import { IThemeManager } from '@toasttab/banquet-theme-manager'
// This is because single-spa-css doesn't export these
// See https://github.com/single-spa/single-spa-css/pull/17
declare type CSSLifecycles<ExtraProps> = {
  bootstrap: LifeCycleFn<ExtraProps>
  mount: LifeCycleFn<ExtraProps>
  unmount: LifeCycleFn<ExtraProps>
}

export interface SentryOpts {
  Sentry?: typeof Sentry
  publicKey: string
  projectId: string
  releaseVersion?: string
  projectName?: string
}

export interface InitSentryValue {
  hub: Sentry.Hub
  client: Sentry.BrowserClient
}

export type banquetSingleSpaReactOpts = SingleSpaReactOpts<
  BanquetCustomProps & AppProps
> & {
  cssScope: string
  portalContainers?: string[]
  sentry?: SentryOpts
  initSentry?: (opts: SentryOpts) => InitSentryValue
  singleSpaCssLifecycles?: CSSLifecycles<BanquetCustomProps>
  sentryErrorBoundary?: (
    error: Error,
    info: React.ErrorInfo,
    props: BanquetProps
  ) => React.ReactElement
  theme?: IThemeManager<BanquetCustomProps & AppProps>
}

// We can expect that we always return and array of lifecycles
type BanquetSingleSpaReact<ExtraProps = BanquetCustomProps & AppProps> = {
  bootstrap: Array<LifeCycleFn<ExtraProps>>
  mount: Array<LifeCycleFn<ExtraProps>>
  unmount: Array<LifeCycleFn<ExtraProps>>
  update: Array<LifeCycleFn<ExtraProps> | undefined>
}

type ProviderFn = (
  component: banquetSingleSpaReactOpts['rootComponent']
) => React.FC

const withProviders = (app: React.FC, providersFns: ProviderFn[]) =>
  providersFns
    .filter(Boolean)
    .reduce((appToWrap, withProvider) => withProvider(appToWrap), app)

export function banquetSingleSpaReact(
  options: banquetSingleSpaReactOpts
): BanquetSingleSpaReact {
  const waitForDomElementLifecycles = waitForDomElement()
  const sentryPlugin = banquetSentry(options)
  const portalCssLifecycles = portalCssScope(options)

  // Cleanup required - once theming is rolled out to all apps we can remove this turnary checks
  const csslifecyles = options.theme
    ? options.theme.getLifecycles()
    : banquetSingleSpaCss(options).lifecycles

  // Cleanup required - once theming is rolled out to all apps we can remove this turnary checks
  const cssScopeLifeCycle = options.theme
    ? childSpaCssScopeLifeCycle(options)
    : {
        bootstrap: (props: AppProps) => Promise.resolve(props)
      }

  const reactLifecycles = singleSpaReact({
    ...options,
    domElementGetter: mountElementWithScope(options),
    errorBoundary:
      options.sentryErrorBoundary || sentryPlugin?.sentryErrorBoundary,
    rootComponent: withProviders(
      options.rootComponent as React.FC,
      [sentryPlugin?.withSentry] as ProviderFn[]
    )
  })

  // force unmount resolved when Parcels are mounted more than
  // once on a page.
  function safeReactUnmount(props: BanquetCustomProps & AppProps) {
    const multiMode: Boolean = !!props?.mode
    const result = reactLifecycles.unmount(props)
    if (multiMode) {
      return Promise.resolve(props)
    }
    return result
  }

  return {
    bootstrap: [
      reactLifecycles.bootstrap,
      cssScopeLifeCycle.bootstrap,
      csslifecyles.bootstrap,
      sentryPlugin.lifecycles.bootstrap
    ],
    mount: [
      waitForDomElementLifecycles.mount,
      sentryPlugin.lifecycles.mount,
      csslifecyles.mount,
      portalCssLifecycles.mount,
      reactLifecycles.mount
    ],
    unmount: [
      sentryPlugin.lifecycles.unmount,
      safeReactUnmount,
      portalCssLifecycles.unmount,
      csslifecyles.unmount
    ],
    update: [reactLifecycles.update]
  }
}

export { useSentry, initSentry, useIntlProps }
