const DEPENDENCY_IN_CDN_REGEX =
  /static\/\w+\/projects\/scripts\/((?:@[\w-]*\/)?[\w-]*)\/(\d*\.\d*\.\d*)/i

export function guessExternalDependencies() {
  let dependencies: string[] = []
  for (const [location] of System.entries()) {
    const matches = location.match(DEPENDENCY_IN_CDN_REGEX)
    if (matches) {
      const [, dependency] = matches
      dependencies.push(dependency)
    }
  }
  return dependencies
}

/**
 * reportExternalDependencies
 * Utility that reads effective import map to report their versions at runtime.
 * Dependencies will be guessed based on the module location (expected to be
 * exposed by the CDN, so the location should be like
 * `/static/<sha>/projects/scripts/<dependency_name>/<version>`).
 */
export function getExternalDependencyVersions() {
  const dependencies = new Map<string, string>(
    guessExternalDependencies().map((dep) => [dep, 'unknown'])
  )
  for (const [location] of System.entries()) {
    const matches = location.match(DEPENDENCY_IN_CDN_REGEX)
    if (matches) {
      const [, dependency, version] = matches
      if (dependencies.has(dependency)) {
        dependencies.set(dependency, version)
      }
    }
  }
  // An object is usually easier to deal with than a map (especially for
  // Sentry tags).
  return Object.fromEntries(dependencies)
}
