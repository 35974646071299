import { BanquetCustomProps } from '@toasttab/banquet-types'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
import { AppProps } from 'single-spa'
import { elementReady } from '../elementReady'

/**
 * waitForDomElement this is a lifecycle function that will wait for a DOM element
 * to be available before resolving the promise. This is useful for applications
 * that are not mounted at the root of the DOM.
 *
 * The default single-spa timeout is 30000ms for this mount function.
 *
 * @returns
 */
export function waitForDomElement(): {
  mount: (
    props: BanquetCustomProps & AppProps
  ) => Promise<BanquetCustomProps & AppProps>
} {
  async function mount(props: BanquetCustomProps & AppProps) {
    if (props.deferredDomElement) {
      try {
        await elementReady(props.deferredDomElement)
      } catch (err) {
        if (getCurrentEnvironment() !== ToastEnvironment.PROD) {
          console.error(err)
        }
        return Promise.reject(err)
      }
    }
    return Promise.resolve(props)
  }
  return {
    mount
  }
}
