import * as React from 'react'
import { useImportParcelConfig } from './useImportParcelConfig'
import { SingleSpaContext } from 'single-spa-react'
import { Parcel } from 'single-spa'

export type BanquetLoaderExperimentalProps = {
  name: string
  LoadingComponent?: React.ComponentType
  mode?: string | null
  id?: string | null
  className?: string
}

let count = 0

export const BanquetLoaderExperimental = ({
  name,
  className,
  mode = null,
  LoadingComponent = () => null
}: BanquetLoaderExperimentalProps) => {
  const customProps = React.useContext(SingleSpaContext)

  const mountPoint = React.useRef<HTMLDivElement>(null)
  const parcel = React.useRef<Parcel | null>(null)
  const instanceIdRef = React.useRef(count++)
  const legacyClassName = [name, mode].filter((el) => !!el).join('-')
  const longName = [name, mode, instanceIdRef.current]
    .filter((el) => !!el)
    .join('__')
  const { loading, error, parcelConfig } = useImportParcelConfig(longName)

  React.useLayoutEffect(() => {
    // If we have a parcelConfig and it's not mounted, mount it
    if (
      parcelConfig &&
      (!parcel.current || parcel.current.getStatus() === 'NOT_MOUNTED')
    ) {
      parcel.current = customProps.singleSpa.mountRootParcel(parcelConfig, {
        domElement: mountPoint.current,
        ...customProps,
        mode
      })
    }

    return () => {
      // Cleanup if the parcel is still mounted
      if (parcel.current && parcel.current.getStatus() === 'MOUNTED') {
        parcel.current.unmount()
      }
    }
  }, [customProps, parcelConfig, mode])

  return (
    <div
      className={`single-spa-application:${legacyClassName} ${className}`}
      data-testid={longName}
      ref={mountPoint}
    >
      {error ? <div>Failed to load</div> : null}
      {loading ? <LoadingComponent /> : null}
    </div>
  )
}
