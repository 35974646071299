import * as React from 'react'
import { ParcelConfig } from 'single-spa'

export type ParcelConfigExtended = ParcelConfig & {
  name: string
  banquetSingleSpaReactFactory?: () => ParcelConfig & { name: string }
}

type State = {
  loading: boolean
  error: boolean
  parcelConfig: ParcelConfigExtended | null
}

/**
 * useImport
 * @description
 * This hook is used to import a parcel config from a remote module.
 * @param name - The name of the parcel config to import
 * @returns {State} - The state of the parcel config
 */
export function useImportParcelConfig(name: string): State {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [parcelConfig, setParcelConfig] =
    React.useState<ParcelConfigExtended | null>(null)

  React.useEffect(() => {
    let ignore = false
    setLoading(true)

    System.import<ParcelConfigExtended>(name)
      .then((importedParcelConfig) => {
        if (ignore) return

        if (importedParcelConfig?.banquetSingleSpaReactFactory) {
          const config = importedParcelConfig.banquetSingleSpaReactFactory()
          config.name = name
          setParcelConfig(config)
        } else {
          setParcelConfig(importedParcelConfig)
        }
        setLoading(false)
      })
      .catch((err) => {
        if (!ignore) {
          console.error(`Error importing parcel config ${name}:`, err)
          setLoading(false)
          setError(true)
        }
      })

    return () => {
      ignore = true
    }
  }, [name])

  return {
    loading,
    error,
    parcelConfig
  }
}
