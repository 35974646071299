import { useEffect, useState } from 'react'
export const useJsLoader = (name: string, mode?: string) => {
  const [bundle, setBundle] = useState<System.Module | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setLoading(true)
    System.import(name)
      .then((bundle: System.Module) => {
        const bundleName = bundle?.name || name
        // When using the modes approach we need to name each bundle
        // uniquely - this function generates that unique name
        const uniqueName = mode ? `${bundleName}-${mode}` : bundleName
        setBundle({ ...bundle, name: uniqueName })
        setLoading(false)
      })
      .catch((e: Error) => {
        setError(e)
      })
  }, [name, mode])

  return { bundle, loading, error }
}
