import * as BrowserSentry from '@sentry/browser'
import { getCurrentEnvironment } from '@toasttab/current-environment'

export type BanquetSentryProps = {
  Sentry?: typeof BrowserSentry
  publicKey: string
  projectId: string
  options?: {
    debug: boolean
  }
  releaseVersion?: string
  projectName?: string
}
export function initSentry({
  Sentry = BrowserSentry,
  publicKey,
  projectId,
  options = { debug: true },
  releaseVersion,
  projectName
}: BanquetSentryProps) {
  if (!isPublicKeyValid(publicKey)) {
    throw new Error(
      'The publicKey must be of the pattern <a-zA-Z0-9>@<a-zA-Z0-9>'
    )
  }

  const name = projectName ? `${projectName}@` : ''
  const version = releaseVersion || process.env.PKG_VERSION || ''
  const release = /^\d+$/.test(version)
    ? `${name}${version}.0.0`
    : /^\d+\.\d+\.\d+$/.test(version)
    ? `${name}${version}`
    : version

  const environment = getCurrentEnvironment()
  const dsn = `https://${publicKey}.ingest.sentry.io/${projectId}`
  const client = new Sentry.BrowserClient({
    dsn,
    environment,
    release,
    debug: options.debug,
    transport: Sentry.makeFetchTransport,
    stackParser: Sentry.defaultStackParser,
    integrations: Sentry.defaultIntegrations
  })
  const hub = new Sentry.Hub(client)
  return {
    hub,
    client
  }
}

function isPublicKeyValid(key: string) {
  return /[a-zA-Z0-9]+@[a-zA-Z0-9]+/.test(key)
}
