import * as React from 'react'
import { AppProps, CustomProps } from 'single-spa'
import { SingleSpaContext } from 'single-spa-react'

/**
 * this is a backwards compatible way to provide children to a FC
 *
 * It does not appear that React.FC<{value: ...}> works anymore in react@18
 *
 * This component definition in react@17 used to allow you to provide the
 * expected props in the component definition and merge it with a the blessed
 * way to define what a child is.
 */
type Props = React.PropsWithChildren<{ value: CustomProps & AppProps }>

/**
 * Useful for Storybook components. SingleSpa props are mocked and shouldn't
 * have any impact in loading your component in Storybook.
 */
export const SetSingleSpaContext = ({ children, value }: Props) => (
  <SingleSpaContext.Provider value={value}>
    {children}
  </SingleSpaContext.Provider>
)
