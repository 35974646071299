import { BanquetCustomProps } from '@toasttab/banquet-types'

export function resolveCSS(name: string): string {
  return System.resolve(`${name}.css`)
}

export function doesLinkExist(url: string): HTMLElement | null {
  return document.querySelector('link[href="' + url + '"]')
}

/**
 * Injects the css link into the head of the html document.
 * @param name Name of the SPA to mount.
 * @param url URL pointing to the CSS file to load.
 * @returns The `link` node mounted in the DOM `head`.
 */
export function createLinkInHead(name: string, url: string) {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.setAttribute('data-banquet-css', name)
  link.href = url
  document.head.appendChild(link)
  return link
}

export function getName(props: BanquetCustomProps) {
  return props?.appName || props.name
}
